import React, { useContext } from 'react';
import useTranslation from '@/hooks/useTranslation';
import { propType } from 'graphql-anywhere';
import { shape, string, bool, number } from 'prop-types';
import contractFragment from '@/api/Contract/contract-fragment.gql';
import contractMilestoneFragment from '@/api/Contract/contract-milestone-fragment.gql';
import { formatDate } from '@/helpers/date';
import priceFormat from '@/helpers/priceFormat';
import UserContext from '@/permissions/contexts/UserContext';
import { EXPERT } from '@/constants/userKind';
import { tradWithCountSuffixKey } from '@/helpers/i18n';
import { ReactComponent as InfoIcon } from '@/images/c-info.svg';
import Tooltip from '@/components/Tooltip';
import {
  FIFTEEN_WORKING_DAYS,
  FIVE_WORKING_DAYS,
} from '@/constants/PaymentPeriod';
import config from '@/_config';
import './styles.scss';

function MilestonePriceAndDate({
  milestone,
  contract,
  primary,
  classes,
  withModification,
  newPrice,
}) {
  const { t } = useTranslation();
  const currentUser = useContext(UserContext);
  const { price, vat, begin_date, end_date } = milestone;
  const amount = milestone.amount(contract.kind);
  const paymentPeriod = milestone.get('payment_period');

  const indemnity = milestone.get('professional_indemnity') === 'true';
  const professionalIndemnityValue = milestone.get(
    'professional_indemnity_percentage'
  );
  const professionalIndemnityPercentage = professionalIndemnityValue * 100;
  // If the price is changed (in delivery with modification) the indemnity is computed, otherwise it takes the value computed from the API
  const professionalIndemnityInCurrency = withModification
    ? newPrice * professionalIndemnityValue
    : milestone.get('professional_indemnity_in_currency');

  const discountValue = milestone.get('discount');
  const discountPercentage = parseFloat((discountValue * 100).toFixed(2));
  // If the price is changed (in delivery with modification) the discount is computed, otherwise it takes the value computed from the API
  const discountCurrency = withModification
    ? (newPrice - professionalIndemnityInCurrency) * discountValue
    : milestone.get('discount_in_currency');

  const vatInCurrency = milestone.get('vat_in_currency');
  const totalTTC = milestone.get('total_ttc');

  // If the price is changed (in delivery with modification) the totalHt is computed, otherwise it takes the value computed from the API
  const totalHt = withModification
    ? newPrice - professionalIndemnityInCurrency - discountCurrency
    : price;

  const vatPrice = withModification
    ? parseFloat(((vat * totalHt) / 100).toFixed(2))
    : vatInCurrency;
  const newPriceTtc = vatPrice + totalHt;

  const totalTtc = priceFormat(withModification ? newPriceTtc : totalTTC, {
    minDigits: 2,
    maxDigits: 2,
  });

  return (
    <div className={classes.priceAndDate}>
      <div>
        <div>
          {`${formatDate(begin_date, 'D MMMM YYYY')} - ${formatDate(
            end_date,
            'D MMMM YYYY'
          )}`}
        </div>
        {currentUser.get('kind') === EXPERT && (
          <>
            {config.proposal.displayIndemnity && (
              <div>
                {t('milestone.indemnity.title')}
                <span
                  className={`${
                    withModification ? 'mred' : primary
                  } fw-bold m-l-xs`}
                >
                  {t(
                    `proposal.section.indemnity.${
                      indemnity ? 'enabled' : 'disabled'
                    }`
                  )}
                  {indemnity && (
                    <>
                      <span className="m-l-xs">
                        {t('proposal.section.indemnity.percentage', {
                          percentage: professionalIndemnityPercentage.toFixed(
                            2
                          ),
                        })}
                      </span>
                      <span className="p-l-xxs">
                        ({priceFormat(professionalIndemnityInCurrency, {
                          minDigits: 2,
                          maxDigits: 2,
                        })}
                        <span className="p-l-xxs">{t('currency.symbol')}</span>)
                      </span>
                    </>
                  )}
                </span>
              </div>
            )}
            {config.proposal.displayPaymentPeriod && (
              <div>
                {t('milestone.payment_period.title')}
                <span
                  className={`${
                    withModification ? 'mred' : primary
                  } fw-bold m-l-xs`}
                >
                  {t(
                    tradWithCountSuffixKey(
                      `proposal.section.payment_period.choice.${paymentPeriod}_x_percent_discount`,
                      discountPercentage,
                      true
                    ),
                    {
                      count: discountPercentage,
                    }
                  )}
                  {discountValue > 0 && (
                    <>
                      <span>
                        ({priceFormat(discountCurrency, {
                          minDigits: 2,
                          maxDigits: 2,
                        })}
                        <span className="p-l-xxs">{t('currency.symbol')}</span>)
                      </span>
                    </>
                  )}
                  {(paymentPeriod === FIFTEEN_WORKING_DAYS ||
                    paymentPeriod === FIVE_WORKING_DAYS) && (
                    <span className="p-l-xxs MilestoneCard__tooltip">
                      <Tooltip text="milestone_card.payment_period.tooltip.text">
                        <InfoIcon />
                      </Tooltip>
                    </span>
                  )}
                </span>
              </div>
            )}
          </>
        )}
      </div>
      <div>
        {!contract.isForfeit() && (
          <div className="fw-bold fs-small">
            {t(
              `contract.milestone.total_ht${
                currentUser.get('kind') === EXPERT ? '.expert' : ''
              }`
            )}
            <span className={`${withModification ? 'mred' : primary} blank-b`}>
              {priceFormat(totalHt, { minDigits: 2, maxDigits: 2 })}
              <span className="blank-b">{t('currency.symbol')}</span>
            </span>
          </div>
        )}
        {contract.isForfeit() &&
          amount && (
            <div className="fw-bold">
              {t('contract.milestone.amount_total_duty', {
                amount,
                currency: t('currency.symbol'),
              })}
            </div>
          )}
        <div>
          {t('contract.milestone.vat', { vat })}
          <span className={`${withModification ? 'mred' : primary} fw-bold`}>
            {priceFormat(vatPrice, { minDigits: 2, maxDigits: 2 })}
            <span className="blank-b">{t('currency.symbol')}</span>
          </span>
        </div>
        <div>
          {t(
            `contract.milestone.total_ttc${
              currentUser.get('kind') === EXPERT ? '.expert' : ''
            }`
          )}
          <span className={`${withModification ? 'mred' : primary} fw-bold`}>
            {totalTtc} {t('currency.symbol')}
          </span>
        </div>
      </div>
    </div>
  );
}

MilestonePriceAndDate.propTypes = {
  milestone: propType(contractMilestoneFragment).isRequired,
  contract: propType(contractFragment).isRequired,
  primary: string,
  classes: shape({
    priceAndDate: string,
  }),
  withModification: bool,
  newPrice: number,
};
MilestonePriceAndDate.defaultProps = {
  primary: 'primary',
  classes: { priceAndDate: null },
  withModification: false,
  newPrice: null,
};

export default MilestonePriceAndDate;
