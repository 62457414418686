import React from 'react';
import { bool } from 'prop-types';
import { propType } from 'graphql-anywhere';
import { get } from 'lodash';
import userFragment from '@/api/User/fragment';
import NewMission from '../NewMission';
import Conversations from '../Conversations';
import Notifications from '../Notifications';
import OfflineDrawer from './OfflineDrawer';
import MenuDrawer from './MenuDrawer';
import SearchDrawer from './SearchDrawer';
import Documents from '../Documents';
import MeltingSpot from '@/components/MeltingSpot';
import config from '@/_config';
import './styles.scss';

const MobileNav = ({ currentUser, searchBarVisible, ...otherProps }) => {
  if (!currentUser) {
    return (
      <nav className="Header__nav">
        <SearchDrawer currentUser={currentUser} {...otherProps} />
        <OfflineDrawer {...otherProps} />
      </nav>
    );
  }

  return (
    <nav className="Header__nav">
      {searchBarVisible && (
        <SearchDrawer currentUser={currentUser} {...otherProps} />
      )}
      <NewMission userKind={currentUser.kind} {...otherProps} />
      {config.settings.showDocuments && <Documents />}
      {config.settings.showConversations && <Conversations />}
      {config.global.meltingSpot(get(currentUser, 'kind')) && (
        <MeltingSpot mobile />
      )}
      <Notifications mobile />
      <MenuDrawer userKind={currentUser.kind} {...otherProps} />
    </nav>
  );
};

MobileNav.propTypes = {
  currentUser: propType(userFragment),
  searchBarVisible: bool,
};

MobileNav.defaultProps = {
  currentUser: null,
  searchBarVisible: true,
};

export default MobileNav;
