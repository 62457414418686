import {
  getMyMissionsRoute,
  getProposalsRoute,
  getMyApplicationsRoute,
  getContractsRoute,
  getMissionsMatchingRoute,
  getInvoicesRoute,
  getClientPoolRoute,
  getCommunityRoute,
} from '@/helpers/router';
import { ReactComponent as Paper } from '@/images/paper-2.svg';
import { ReactComponent as Handshake } from '@/images/handshake.svg';
import { ReactComponent as Zip } from '@/images/zip.svg';
import { ReactComponent as File } from '@/images/file.svg';
import { ReactComponent as Invoice } from '@/images/file-contract.svg';
import { ReactComponent as Team } from '@/images/team.svg';
import { ReactComponent as Graduation } from '@/images/graduation.svg';
import InvoiceCount from '@/components/Invoice/InvoiceCount';
import ProposalCount from '@/components/ProposalCount';
import ContractCount from '@/components/Contract/ContractCount';
import MyMissionCount from '@/components/MyMissionCount';
import MyApplicationsCount from '@/components/MyApplicationsCount';
import MeltingSpot from '@/components/MeltingSpot';
import TrainingsLink from '@/components/TrainingsLink';

export const MY_MISSIONS_LINK = {
  id: 'n_mymissions',
  label: 'header.nav.myMissions',
  to: getMyMissionsRoute(),
  icon: Zip,
  badge: {
    component: MyMissionCount,
  },
};

export const CONTRACTS_LINK = {
  id: 'n_contracts',
  label: 'header.nav.contracts',
  to: getContractsRoute(),
  icon: Handshake,
  badge: {
    component: ContractCount,
  },
};

export const INVOICES_LINK = {
  id: 'n_invoices',
  label: 'header.nav.invoices',
  to: getInvoicesRoute(),
  icon: Invoice,
  badge: {
    component: InvoiceCount,
  },
};

export const MY_APPLICATIONS_LINK = {
  id: 'n_myApplications',
  label: 'header.nav.applications',
  to: getMyApplicationsRoute(),
  icon: File,
  badge: {
    component: MyApplicationsCount,
  },
};

export const PROPOSALS_LINK = {
  id: 'n_proposals',
  label: 'header.nav.proposals',
  to: getProposalsRoute(),
  icon: Paper,
  badge: {
    component: ProposalCount,
  },
};

export const MISSIONS_LINK = {
  id: 'n_missions',
  label: 'header.nav.missions',
  to: getMissionsMatchingRoute(),
  icon: Zip,
};

export const CLIENT_POOL_LINK = {
  id: 'n_clientpool',
  label: 'header.nav.clientPool',
  to: getClientPoolRoute(),
  icon: Team,
};

export const MY_SUBSCRIPTION_EXPERT_LINK = {
  id: 'mysubscription',
  label: 'header.nav.my_subscription',
  to: process.env.REACT_APP_EXPERT_BILLING_LINK_SWIM,
  target: '_blank',
  icon: Invoice,
};

export const MY_SUBSCRIPTION_CLIENT_LINK = {
  id: 'mysubscription',
  label: 'header.nav.my_subscription',
  to: process.env.REACT_APP_CLIENT_BILLING_LINK_SWIM,
  target: '_blank',
  icon: Invoice,
};

export const MY_COMMUNITY = {
  id: 'mycommunity',
  label: 'header.nav.my_community',
  to: getCommunityRoute(),
  icon: Team,
  component: MeltingSpot,
};

export const MY_TRAININGS_LINK = {
  id: 'm_trainings',
  label: 'header.menu.trainings',
  to: process.env.REACT_APP_TRAININGS_LINK_GEEK,
  target: '_blank',
  icon: Graduation,
  component: TrainingsLink,
  tooltip: {
    key: 'header.menu.trainings.disabled',
  },
};

export const CLIENT_LINKS = [MY_MISSIONS_LINK, CONTRACTS_LINK, INVOICES_LINK];

export const EXPERT_LINKS = [
  MY_APPLICATIONS_LINK,
  PROPOSALS_LINK,
  CONTRACTS_LINK,
  INVOICES_LINK,
];
