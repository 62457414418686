import React from 'react';
import { string, func, shape, bool } from 'prop-types';
import { get } from 'lodash';
import useTranslation from '@/hooks/useTranslation';
import Tooltip from '@/components/Tooltip';
import HeaderLink from '../Link';
import './styles.scss';

const TooltipLink = ({ item, disabled }) => {
  const { t } = useTranslation();

  const renderContent = () => (
    <>
      {item.icon && get(item, 'displayIcon', true) && <item.icon />}
      <span>{t(item.label)}</span>
    </>
  );

  return (
    <>
      {disabled ? (
        <div className="Tooltip__link">
          <Tooltip title={t(item.tooltip.key)}>
            <button
              className="Header__link Header__link--disabled"
              type="button"
              disabled
            >
              {renderContent()}
            </button>
          </Tooltip>
        </div>
      ) : (
        <HeaderLink to={item.to} target={item.target} disabled={disabled}>
          {renderContent()}
        </HeaderLink>
      )}
    </>
  );
};

TooltipLink.propTypes = {
  item: shape({
    id: string.isRequired,
    label: string.isRequired,
    tooltip: shape({
      key: string.isRequired,
    }).isRequired,
    to: string,
    target: string,
    icon: func,
  }).isRequired,
  disabled: bool,
};

TooltipLink.defaultProps = {
  disabled: false,
};

export default TooltipLink;
