import React, { useEffect, useState } from 'react';
import { noop } from 'lodash';
import cx from 'classnames';
import { func, arrayOf, string, any, objectOf } from 'prop-types';
import ReactQuill from 'react-quill';
import i18n from '@/i18n';
import useTranslation from '@/hooks/useTranslation';
import 'react-quill/dist/quill.snow.css';
import './styles.scss';

function Editor({ className, modules, onFocus, onBlur, ...props }) {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);

  function handleFocus(range, source, editor) {
    setFocused(true);
    onFocus(editor.getHTML());
  }

  function handleBlur(previousRange, source, editor) {
    setFocused(false);
    onBlur(editor.getLength() > 1 ? editor.getHTML() : '');
  }

  useEffect(() => {
    // override placeholder of tooltip
    const quillTooltipPlaceholder = document.querySelectorAll(
      '[data-video="Embed URL"]'
    );
    Array.from(quillTooltipPlaceholder).forEach((element) =>
      element.setAttribute('data-video', t('editor.quill.placeholder'))
    );

    // override labels with locale
    const quillContainer = document.getElementsByClassName(
      'ql-container ql-snow'
    );
    Array.from(quillContainer).forEach((item) =>
      item.classList.add(`ql-${i18n.language.replace(/-[A-Z]{2}/, '')}`)
    );
  });

  return (
    <ReactQuill
      className={cx('Editor', { 'Editor--focused': focused }, className)}
      modules={{
        toolbar: [
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image', 'video'],
          ['clean'],
        ],
        ...modules,
      }}
      {...props}
      formats={[...props.formats, 'image', 'video']}
      onFocus={handleFocus} // Laisser derriere le spread, pour set le state correctement
      onBlur={handleBlur}
    />
  );
}

Editor.propTypes = {
  className: string,
  formats: arrayOf(string),
  modules: objectOf(any),
  onFocus: func,
  onBlur: func,
};

Editor.defaultProps = {
  className: null,
  formats: [
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ],
  modules: {},
  onFocus: noop,
  onBlur: noop,
};

export default Editor;
