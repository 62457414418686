import React from 'react';
import { string, bool } from 'prop-types';
import { filter, memoize, identity, isNull } from 'lodash';
import config from '@/_config';
import { isAdmin, getToken } from '@/helpers/auth';
import {
  getLogoutRoute,
  getProfileRoute,
  getBookmarksRoute,
  getSettingsRoute,
  getHelpRoute,
  getNdaRoute,
  getClientPoolsRoute,
  getCommunityWithParamRoute,
} from '@/helpers/router';
import { ReactComponent as People } from '@/images/people.svg';
import { ReactComponent as Settings } from '@/images/settings.svg';
import { ReactComponent as Admin } from '@/images/admin.svg';
import { ReactComponent as Logout } from '@/images/logout.svg';
import { ReactComponent as Bookmark } from '@/images/bookmark.svg';
import { ReactComponent as Question } from '@/images/question.svg';
import { ReactComponent as Nda } from '@/images/pdf-approval.svg';
import { ReactComponent as Info } from '@/images/c-info.svg';
import { ReactComponent as Pen } from '@/images/pen.svg';
import { ReactComponent as Handshake } from '@/images/handshake.svg';
import { ReactComponent as Team } from '@/images/team.svg';
import LinkList from './LinkList';
import { backofficeUrl } from '@/constants/environment';
import { EXPERT } from '@/constants/userKind';

const getMenuItems = memoize((token, userKind) =>
  filter(
    [
      config.global.showProfile(userKind) && {
        id: 'm_profile',
        label: 'header.menu.profile',
        to: getProfileRoute(),
        icon: People,
      },
      config.global.showClientPools(userKind) && {
        id: 'm_client_pools',
        label: 'header.menu.client_pools',
        to: getClientPoolsRoute(),
        icon: Team,
      },
      config.global.showNda(userKind) && {
        id: 'm_nda',
        label: 'header.menu.nda',
        to: getNdaRoute(),
        icon: Nda,
      },
      config.global.showBookmarks(userKind) && {
        id: 'm_bookmarks',
        label: 'header.menu.bookmarks',
        to: getBookmarksRoute(),
        icon: Bookmark,
      },
      {
        id: 'separator',
        kind: 'separator',
      },
      {
        id: 'm_settings',
        label: 'header.menu.settings',
        icon: Settings,
        to: getSettingsRoute(),
      },
      config.global.showBilling && {
        id: 'm_subscription',
        label: 'header.nav.my_subscription',
        to:
          process.env[`REACT_APP_${userKind.toUpperCase()}_BILLING_LINK_SWIM`],
        icon: Pen,
        target: '_blank',
      },
      isAdmin() && {
        id: 'm_admin',
        label: 'header.menu.backoffice',
        to: backofficeUrl,
        target: '_blank',
        icon: Admin,
      },
      config.global.showAssistance && {
        id: 'm_assistance',
        label: 'header.menu.assistance',
        // to: process.env.REACT_APP_ASSISTANCE_URL,
        to: getCommunityWithParamRoute(),
        icon: Question,
        // TODO: remove target when deeplink is ready
        // target: '_blank',
      },
      !isNull(config.header.partner) &&
        userKind === EXPERT && {
          id: 'm_partner',
          label: 'header.menu.partner',
          to: config.header.partner,
          target: '_blank',
          icon: Handshake,
        },
      {
        id: 'm_help',
        label: 'header.menu.help',
        to: getHelpRoute(),
        icon: Info,
      },
      {
        id: 'm_logout',
        label: 'header.menu.logout',
        to: getLogoutRoute(),
        icon: Logout,
      },
    ],
    identity
  )
);

const Menu = ({ desktop, companyKind, userKind, ...props }) => (
  <LinkList items={getMenuItems(getToken(), userKind)} {...props} />
);

Menu.propTypes = {
  userKind: string.isRequired,
  desktop: bool,
};

Menu.defaultProps = {
  desktop: false,
};

export default Menu;
