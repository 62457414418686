export const DEFAULT_DISCLAIMER = {
  title: 'mission.message.disclaimer.title',
  text: 'mission.message.disclaimer.text',
};

export const DISCLAIMER_WITH_VIDEO = {
  ...DEFAULT_DISCLAIMER,
  link: {
    title: 'mission.message.disclaimer.action.title',
    modalName: 'matches_video_disclaimer',
  },
};
