import React from 'react';
import { func, arrayOf, shape, string, any } from 'prop-types';
import { noop } from 'lodash';
import Popover from '@/components/Popover';
import HeaderLink from '../Link';
import LinkList from '../LinkList';

const InnerNav = ({ t, onItemClick, items }) =>
  items.map((item) => {
    // Check if the item has a component attribute
    if (item.component) {
      const Component = item.component;
      return <Component key={item.id} item={{ ...item, displayIcon: false }} />;
    }

    return 'menu' in item ? (
      <Popover
        key={item.id}
        spacing={18}
        placement="bottom"
        className="Header__link"
        renderReference={({ ref, getButtonProps }) => (
          <button type="button" {...getButtonProps({ ref })}>
            {t(item.label)}
          </button>
        )}
        renderPopper={() => <LinkList items={item.menu} t={t} />}
      />
    ) : (
      <HeaderLink
        to={item.to}
        onClick={onItemClick}
        key={item.id}
        className={item.badge ? 'Header__link__text' : ''}
        target={item.target}
      >
        {t(item.label)} {item.badge && <item.badge.component />}
      </HeaderLink>
    );
  });

InnerNav.propTypes = {
  t: func.isRequired,
  items: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      pathname: string,
      menu: arrayOf(any),
      component: any, // Allow a component to be passed in the item
    })
  ),
  onItemClick: func,
};

InnerNav.defaultProps = {
  items: [],
  onItemClick: noop,
};

export default InnerNav;
