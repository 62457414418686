import React from 'react';
import { func } from 'prop-types';
import Modal from '@/components/Modal';
import useTranslation from '@/hooks/useTranslation';
import config from '@/_config';

function MatchesVideoDisclaimerModal({ close }) {
  const { t } = useTranslation();

  return (
    <Modal
      name="matches_video_disclaimer"
      size="large"
      close={close}
      onCancel={close}
      title={t('matches_video_disclaimer.modal.title')}
      closable
      hideActions
    >
      <div className="loomVideoWrapper">
        <iframe
          width="560"
          height="315"
          src={
            process.env[`REACT_APP_${config.name.toUpperCase()}_MATCHING_VIDEO`]
          }
          title="Loom video"
          allowFullScreen
          webkitallowfullscreen
          mozallowfullscreen
          frameBorder="0"
        />
      </div>
    </Modal>
  );
}

MatchesVideoDisclaimerModal.propTypes = {
  close: func.isRequired,
};

export default MatchesVideoDisclaimerModal;
