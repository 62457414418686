import React, { useEffect } from 'react';
import { objectOf, any, bool } from 'prop-types';
import useTranslation from '@/hooks/useTranslation';
import HeaderLink from '@/components/Header/Nav/Link';
import Tooltip from '@/components/Tooltip';
import { ReactComponent as Team } from '@/images/team.svg';
import { getCommunityRoute } from '@/helpers/router';

function MeltingSpot({ item, mobile }) {
  const { t } = useTranslation();
  // TODO: implement deeplink logic params when feature is working in meltingSpot

  useEffect(() => {
    // Check if the script is already in the document
    const existingScript = document.querySelector(
      'script[src="https://cdn.jsdelivr.net/npm/@meltingspot/meltingspot-js"]'
    );

    if (!existingScript) {
      const cdnScript = document.createElement('script');
      cdnScript.src =
        'https://cdn.jsdelivr.net/npm/@meltingspot/meltingspot-js';
      cdnScript.crossOrigin = 'anonymous'; // Adding the crossorigin attribute

      document.head.appendChild(cdnScript);
    }
  }, []);

  return (
    <>
      {mobile ? (
        <HeaderLink to={getCommunityRoute()}>
          <Tooltip title="header.nav.community.tooltip.title">
            <Team />
          </Tooltip>
        </HeaderLink>
      ) : (
        <HeaderLink
          to={item.to}
          key={item.id}
          className={item.badge ? 'Header__link__text' : ''}
          target={item.target}
        >
          {t(item.label)}
        </HeaderLink>
      )}
    </>
  );
}

MeltingSpot.propTypes = {
  item: objectOf(any),
  mobile: bool,
};

MeltingSpot.defaultProps = {
  item: {},
  mobile: false,
};

export default MeltingSpot;
