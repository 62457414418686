import React from 'react';
import { string, arrayOf, func, shape } from 'prop-types';
import { noop, get } from 'lodash';
import { Menu, MenuItem, MenuSeparator } from '@/components/Menu';
import HeaderLink from './Link';

const LinkList = ({ t, items, onItemClick }) => (
  <Menu className="Header__menu">
    {items.map((item) => {
      // Check if the item has a component attribute
      if (item.component) {
        const Component = item.component;
        return (
          <MenuItem key={item.id}>
            <Component item={item} />
          </MenuItem>
        );
      }
      return item.kind === 'separator' ? (
        <MenuSeparator key={item.id} />
      ) : (
        <MenuItem key={item.id}>
          {get(item, 'component') ? (
            <item.component.name item={item} />
          ) : (
            <HeaderLink to={item.to} target={item.target} onClick={onItemClick}>
              {item.icon && <item.icon />}
              <span>
                {t(item.label)} {item.badge && <item.badge.component />}
              </span>
            </HeaderLink>
          )}
        </MenuItem>
      );
    })}
  </Menu>
);

LinkList.propTypes = {
  t: func.isRequired,
  items: arrayOf(
    shape({
      id: string.isRequired,
      label: string,
      pathname: string,
      icon: func,
    })
  ),
  onItemClick: func,
};

LinkList.defaultProps = {
  items: [],
  onItemClick: noop,
};

export default LinkList;
