import React, { useState } from 'react';
import { func, string } from 'prop-types';
import { Trans } from 'react-i18next';
import Modal from '@/components/Modal';
import useTranslation from '@/hooks/useTranslation';

function ValidateProformaModal({
  close,
  id,
  validateProformaInvoice,
  showFlash,
}) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  async function onSubmit() {
    setLoading(true);
    await validateProformaInvoice({
      variables: {
        id,
      },
      refetchQueries: ['getExpertInvoices'],
      awaitRefetchQueries: true,
    });
    setLoading(false);
    close();
    showFlash({
      kind: 'success',
      text: 'validate_proforma.modal.success.text',
    });
  }

  return (
    <Modal
      name="validate_proforma"
      size="large"
      close={close}
      onOk={onSubmit}
      onCancel={close}
      title={t('validate_proforma.modal.title')}
      disableActions={loading}
      closable={!loading}
    >
      <p>
        <Trans
          t={t}
          i18nKey="validate_proforma.modal.content"
          values={{
            mail: t('tenant.billing.mail'),
          }}
          components={[<div className="primary fw-bold">mail</div>]}
        />
      </p>
    </Modal>
  );
}

ValidateProformaModal.propTypes = {
  close: func.isRequired,
  showFlash: func.isRequired,
  validateProformaInvoice: func.isRequired,
  id: string.isRequired,
};

export default ValidateProformaModal;
