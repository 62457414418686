import React from 'react';
import { number, string, objectOf, any, func, bool, oneOf } from 'prop-types';
import cx from 'classnames';
import { get, set, noop, mergeWith, isEmpty } from 'lodash';
import { Query } from 'react-apollo';
import InfiniteScroller from 'react-infinite-scroller';
import Sticky from 'react-stickynode';
import MediaQuery from 'react-responsive';
import useTranslation from '@/hooks/useTranslation';
import LoadingCircle from '@/components/LoadingCircle';
import Result from './Result';
import Message from '@/components/Message';
import { BREAKPOINTS } from '@/constants/screen';
import getCounter from '@/helpers/collections';
import Button from '@/components/Button';
import './styles.scss';

function InfiniteScroll({
  children,
  className,
  layout,
  query,
  queryParams,
  variables,
  offset,
  limit,
  itemsPath,
  renderItem,
  useWindow,
  noResultsText,
  nbResultsText,
  onFinish,
  mergeCustomizer,
  noResultKeyLabel,
  disclaimer,
  openModal,
}) {
  const { t } = useTranslation();
  // TODO: Use it
  const withDisclaimer = !isEmpty(disclaimer);
  const classNames = {
    main: cx('InfiniteScroll', `InfiniteScroll--${layout}`, className),
    loader: cx('InfiniteScroll__loader', {
      [`${className}__loader`]: className,
    }),
    result: cx('InfiniteScroll__result', {
      [`${className}__result`]: className,
    }),
    results: cx(`InfiniteScroll__${layout}`, {
      [`${className}__${layout}`]: className,
    }),
    resultsItem: cx(`InfiniteScroll__${layout}-item`, {
      [`${className}__${layout}-item`]: className,
    }),
    resultsGhost: cx(`InfiniteScroll__${layout}-ghost`, {
      [`${className}__${layout}-ghost`]: className,
    }),
  };

  const loader = (
    <div className={classNames.loader} key="loader">
      <LoadingCircle />
    </div>
  );

  const MessageComponent = () => (
    <Message
      title={t(get(disclaimer, 'title'))}
      text={t(get(disclaimer, 'text'))}
      // According to the config, disclaimer can have a link to trigger a modal
      {...get(disclaimer, 'link') && {
        action: (
          <Button
            variants={['link']}
            onClick={() => openModal(get(disclaimer, 'link.modalName'))}
          >
            {t(get(disclaimer, 'link.title'))}
          </Button>
        ),
      }}
    />
  );

  // TODO: FInish disclaimer logic (see AlgoliaView/content)
  const renderItems = (params) => {
    const { items, fetchMore, nbItems, count, data } = params;
    return (
      <>
        {nbResultsText && (
          <Result
            className={classNames.result}
            i18nKey={nbResultsText}
            count={nbItems}
          />
        )}
        <InfiniteScroller
          element="ul"
          className={classNames.results}
          pageStart={offset}
          initialLoad={false}
          hasMore={items.length < count}
          loadMore={(page) => {
            const currentPage = page || (offset === 0 && 1);

            return fetchMore({
              variables: { offset: currentPage * limit, limit },
              updateQuery: (previousResult = {}, { fetchMoreResult }) => {
                const result = { ...fetchMoreResult };
                if (mergeCustomizer && typeof mergeCustomizer === 'function') {
                  return mergeWith(
                    { ...previousResult },
                    { ...fetchMoreResult },
                    mergeCustomizer
                  );
                }

                set(result, itemsPath, [
                  ...get(previousResult, itemsPath),
                  ...get(result, itemsPath),
                ]);
                return result;
              },
            });
          }}
          useWindow={useWindow}
          loader={loader}
        >
          {items.map((item) => (
            <li key={item.id} className={classNames.resultsItem}>
              {renderItem(item, data)}
            </li>
          ))}
          {layout === 'grid' &&
            Array.from({ length: 5 }).map((__, i) => (
              <li key={`ghost_${+i}`} className={classNames.resultsGhost} />
            ))}
        </InfiniteScroller>
      </>
    );
  };

  const renderListWithDisclaimer = (items, data) => (
    <div className="InfiniteScroll__disclaimer">
      {renderItems(items, data)}
      <MediaQuery maxWidth={BREAKPOINTS.laptop}>
        {(mobile) => (
          <>
            {mobile ? (
              <div className="disclaimer">{MessageComponent()}</div>
            ) : (
              <Sticky top={200} className="disclaimer">
                {MessageComponent()}
              </Sticky>
            )}
          </>
        )}
      </MediaQuery>
    </div>
  );

  return (
    <div className={classNames.main}>
      {query && (
        <Query
          query={query}
          variables={{
            offset,
            limit,
            ...variables,
            ...queryParams,
          }}
        >
          {({ data, loading, fetchMore }) => {
            const items = get(data, itemsPath, []) || [];
            const nbItems = items.length;
            const empty = !items.length;
            if (loading) return loader;
            if (onFinish && !loading) onFinish(data);
            const count = getCounter(data)[itemsPath];
            return (
              <>
                {children(items, data)}
                {empty && (
                  <Result
                    className={classNames.result}
                    i18nKey={noResultsText}
                    noResultKeyLabel={noResultKeyLabel}
                    count={nbItems}
                  />
                )}
                {withDisclaimer &&
                  !empty && (
                    <>
                      {renderListWithDisclaimer({
                        items,
                        fetchMore,
                        nbItems,
                        count,
                        data,
                      })}
                    </>
                  )}
                {!withDisclaimer &&
                  !empty && (
                    <>
                      {renderItems({
                        items,
                        fetchMore,
                        nbItems,
                        count,
                        data,
                      })}
                    </>
                  )}
              </>
            );
          }}
        </Query>
      )}
    </div>
  );
}

InfiniteScroll.propTypes = {
  children: func,
  className: string,
  layout: oneOf(['list', 'grid']),
  query: objectOf(any),
  queryParams: objectOf(any),
  variables: objectOf(any),
  offset: number,
  limit: number,
  useWindow: bool,
  itemsPath: string.isRequired,
  renderItem: func.isRequired,
  noResultsText: string.isRequired,
  nbResultsText: string,
  onFinish: func,
  mergeCustomizer: func,
  noResultKeyLabel: string,
  disclaimer: objectOf(any),
  openModal: func,
};

InfiniteScroll.defaultProps = {
  children: noop,
  className: null,
  layout: 'list',
  queryParams: {},
  variables: null,
  offset: 0,
  limit: 10,
  useWindow: true,
  nbResultsText: null,
  query: null,
  onFinish: null,
  mergeCustomizer: null,
  noResultKeyLabel: '',
  disclaimer: {},
  openModal: null,
};

export default InfiniteScroll;
