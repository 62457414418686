/* eslint-disable react/no-danger */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function RichText({ children }) {
  const [content, setContent] = useState(children);

  useEffect(
    () => {
      // Create a dummy DOM element to parse the HTML string
      const parser = new DOMParser();
      const doc = parser.parseFromString(children, 'text/html');

      // Find all iframes in the parsed HTML
      const iframes = doc.querySelectorAll('iframe.ql-video');

      if (iframes.length > 0) {
        iframes.forEach((iframe) => {
          // Create a div wrapper
          const wrapper = doc.createElement('div');
          wrapper.classList.add('videoWrapper');

          // Wrap the iframe inside the div (if not already wrapped)
          if (!iframe.parentNode.classList.contains('videoWrapper')) {
            iframe.parentNode.insertBefore(wrapper, iframe);
            wrapper.appendChild(iframe);
          }
        });

        // Serialize the updated HTML back to a string
        setContent(doc.body.innerHTML);
      }
    },
    [children]
  );

  return (
    <div className="RichText" dangerouslySetInnerHTML={{ __html: content }} />
  );
}

RichText.propTypes = {
  children: PropTypes.string.isRequired,
};

export default RichText;
